import { VariantProps } from 'class-variance-authority';
import clsx from 'clsx';
import { isFunction } from 'lodash-es';
import React from 'react';
import { ButtonProps } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';
import { button } from '../../electrons/button';
import { Loader } from '../loader/loader';
import { Pressable } from '../pressable/pressable';

export const Button = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & VariantProps<typeof button> & { className?: string; isLoading?: boolean }
>(({ size, variant, rounded, children, className, isLoading, isDisabled, ...props }, ref) => {
  return (
    <Pressable
      className={twMerge(button({ variant, size, rounded }), className)}
      isDisabled={isDisabled}
      ref={ref}
      {...props}>
      {(bag) => (
        <>
          <span
            className={clsx(
              'flex items-center gap-x-2',
              isLoading && 'opacity-0 transition-opacity',
            )}>
            {isFunction(children) ? children(bag) : children}
          </span>
          <span
            className={clsx(
              'absolute inset-0 flex items-center justify-center transition-opacity',
              isLoading ? 'opacity-100' : 'opacity-0',
            )}>
            <Loader variant={isDisabled ? 'muted' : 'light'} />
          </span>
        </>
      )}
    </Pressable>
  );
});
