import { Transition, motion } from 'motion/react';
import { twMerge } from 'tailwind-merge';

const loadingContainerVariants = {
  initial: {
    transition: {
      staggerChildren: 0.2,
    },
  },
  animate: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const loadingDotsVariants = {
  initial: {
    scale: '100%',
  },
  animate: {
    scale: '120%',
  },
};

const loadingDotsTransition: Transition = {
  duration: 0.5,
  repeat: Infinity,
  repeatType: 'mirror',
  ease: 'easeInOut',
};

export enum Variant {
  DARK = 'dark',
  LIGHT = 'light',
  MUTED = 'muted',
}

const variantClasses = {
  [Variant.DARK]: 'bg-grey-700',
  [Variant.MUTED]: 'bg-grey-600',
  [Variant.LIGHT]: 'bg-white',
};

export function Loader({
  variant = 'light',
  className,
}: {
  variant?: `${Variant}`;
  className?: string;
}) {
  return (
    <span className={twMerge('flex h-6 items-center', className)}>
      <motion.span
        animate="animate"
        className="flex gap-x-2"
        initial="initial"
        variants={loadingContainerVariants}>
        <motion.span
          className={twMerge('h-2 w-2 rounded-full', variantClasses[variant])}
          transition={loadingDotsTransition}
          variants={loadingDotsVariants}
        />
        <motion.span
          className={twMerge('h-2 w-2 rounded-full', variantClasses[variant])}
          transition={loadingDotsTransition}
          variants={loadingDotsVariants}
        />
        <motion.span
          className={twMerge('h-2 w-2 rounded-full', variantClasses[variant])}
          transition={loadingDotsTransition}
          variants={loadingDotsVariants}
        />
      </motion.span>
    </span>
  );
}
